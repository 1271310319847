// src/components/ImageCard.js
import React from 'react';
import { FC } from 'react'
import './ImageCard.css';

interface Props {
  imageSrc: string,
  title: string,
  description: string
}

const ImageCard: React.FC<Props> = ({ imageSrc, title, description }) => {
  return (
    <div className="image-card">
      <h3 className="title">{title}</h3>
      <img src={imageSrc} alt={title} className="image" />
    
      <p className="description">{description}</p>
    </div>
  );
};

export default ImageCard;
