import React from 'react';
import './App.css';
import Home from './presentation/pages/home/Home';

const App: React.FC = () => {
  return (
    <div>
      <Home/>
    </div>
  );
}

export default App;
