// src/pages/Home.js
import React from 'react';
import ImageCard from './components/ImageCard';
import './Home.css'

const Home = () => {
  return (
    <div>
      <div className='main_box'>
        <ImageCard imageSrc={`${process.env.PUBLIC_URL}/flexy_v1.0.jpg`} title={'F L E X Y - V 1.0'} description={'개발 중 입니다'} />
      </div>
    </div>
  );
};

export default Home;
